<template>
  <div class="PShop">
    <!-- <van-nav-bar
    title="标题"
    :fixed="true"
  /> -->
    <van-card v-for="(item,key) in list" :key="key"
      num="999"
        price="客服问价"
      :desc="item.detail"
      :title="item.title"
      :thumb="item.images"
      v-on:click="$router.push(`/pshopdesc/${item.id}`)"
    />
    <van-pagination
      v-model="pageIndex"
      :total-items="125"
      :show-page-size="3"
      force-ellipses
      @change="listFn()"
      :fixed="true"
    />
  </div>
</template>
<script>
export default {
  name: 'PShop',
  data(){
    return {
        list:[],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 2
    }
  },
  created(){
      this.listFn();
  },
  methods:{
    listFn(){
      if(this.pageIndex>this.totalPage){
        this.pageIndex = this.totalPage;
        return;
      }
        this.axios.post('/app/pshop',{
            'page': this.pageIndex,
            'limit': this.pageSize,
        }).then((response) => {
            console.log(response);
            this.totalPage = response.totalPage;

            this.list = response.list;
          }).catch(function (error) {
              console.log(error);
          });
    }
  }
}
</script>
